var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('table-data',{attrs:{"title":_vm.title,"table-headers":_vm.tableHeaders,"form-scheme":_vm.formScheme,"resource":_vm.resource,"custom-opt":_vm.customOpt,"allow-default-actions":false,"hide-top-action-btn":false,"show-select":true},on:{"currentItems":_vm.currentItems},scopedSlots:_vm._u([{key:"top-action-buttons",fn:function(){return undefined},proxy:true},{key:"item.team",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pa-2"},[_c('div',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(item.team)+" ")])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.status === true ? 'mdi-check' : 'mdi-close'))])]}},{key:"item.affiliate_hash",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.affiliate_hash !== false ? 'mdi-check' : 'mdi-close'))])]}},{key:"action-buttons",fn:function(ref){
var item = ref.item;
return [_c('table-btn',{attrs:{"item":item,"tooltip-text":_vm.$t('affiliates.table.btn.update'),"icon":"pencil","can":"affiliates.management.update","disabled":!!item.affiliate_hash,"fn":function () { return _vm.$eventBus.$emit((_vm.resource + "_updateItem"), item); }}}),_c('table-btn',{attrs:{"item":item,"tooltip-text":_vm.$t('affiliates.table.btn.remove'),"icon":"delete","can":"affiliates.management.remove","disabled":!!item.affiliate_hash,"fn":function () { return _vm.$eventBus.$emit((_vm.resource + "_removeItem"), item); }}})]}},{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"ripple":false,"value":isSelected,"readonly":item.disabled === true,"disabled":item.disabled === true},on:{"input":function($event){return select($event)}}})]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }