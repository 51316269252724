<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <table-data
          :title="title"
          :table-headers="tableHeaders"
          :form-scheme="formScheme"
          :resource="resource"
          :custom-opt="customOpt"
          :allow-default-actions="false"
          :hide-top-action-btn="false"
          :show-select="true"
          @currentItems="currentItems"
        >
          <template #top-action-buttons>
            <!-- v-btn
              v-can="'affiliates.management.import'"
              color="primary mr-3"
              small
              :loading="syncLoading"
              :disabled="syncLoading"
              @click="sync"
            >
              {{ $t('affiliates.sync') }}
              <v-icon
                right
                dark
              >
                mdi-autorenew
              </v-icon>
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon light>mdi-cached</v-icon>
                </span>
              </template>
            </v-btn-->
          </template>

          <template #[`item.team`]="{ item }">
            <div class="pa-2">
              <div class="mb-1">
                {{ item.team }}
              </div>
            </div>
          </template>

          <template #[`item.status`]="{ item }">
            <v-icon>{{ item.status === true ? 'mdi-check' : 'mdi-close' }}</v-icon>
          </template>

          <template #[`item.affiliate_hash`]="{ item }">
            <v-icon>{{ item.affiliate_hash !== false ? 'mdi-check' : 'mdi-close' }}</v-icon>
          </template>

          <template #action-buttons="{ item }">
            <table-btn
              :item="item"
              :tooltip-text="$t('affiliates.table.btn.update')"
              :icon="`pencil`"
              :can="`affiliates.management.update`"
              :disabled="!!item.affiliate_hash"
              :fn="() => $eventBus.$emit(`${resource}_updateItem`, item)"
            />
            <table-btn
              :item="item"
              :tooltip-text="$t('affiliates.table.btn.remove')"
              :icon="`delete`"
              :can="`affiliates.management.remove`"
              :disabled="!!item.affiliate_hash"
              :fn="() => $eventBus.$emit(`${resource}_removeItem`, item)"
            />
          </template>

          <template v-slot:item.data-table-select="{ item, isSelected, select }">
            <v-simple-checkbox
              :ripple="false"
              :value="isSelected"
              :readonly="item.disabled === true"
              :disabled="item.disabled === true"
              @input="select($event)"
            />
          </template>
        </table-data>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'Affiliates',
  metaInfo() {
    return {
      title: this.$store.getters['app/appTitle'],
      titleTemplate: `${this.$t('affiliates.meta.title')} — %s`
    }
  },
  data: () => ({
    title: '',
    tableHeaders: [],
    formScheme: {},
    resource: 'affiliates.management',
    syncLoading: false,
    customOpt: {
      itemsPerPage: 50
    }
  }),
  async mounted() {
    this.title = this.$t('affiliates.title')
    this.tableHeaders = [
      {
        text: this.$t('affiliates.table.name'),
        align: 'start',
        value: 'name'
      },
      // { text: this.$t('affiliates.table.team'), value: 'team' },
      // {
      //   text: this.$t('affiliates.table.fromCRM'),
      //   value: 'affiliate_hash',
      //   width: '100px',
      //   align: 'center'
      // },
      {
        text: this.$t('affiliates.table.status'),
        value: 'status',
        width: '100px',
        align: 'center'
      }
      // { text: this.$t('affiliates.table.teamId'), value: 'team_id', align: 'center', width: '100px' }
    ]
    this.formScheme = {
      name: {
        type: 'text',
        label: this.$t('affiliates.tableForm.name'),
        createRules: 'req'
        // updateRules: 'req'
      },
      // team_id: {
      //   type: 'autocomplete',
      //   label: this.$t('affiliates.tableForm.team_id'),
      //   items: await this.$store.dispatch('affiliatesTeams/all'),
      //   createRules: [],
      //   updateRules: []
      // },
      status: {
        type: 'switch',
        label: this.$t('affiliates.tableForm.affiliate_status')
      }
    }

    if (this.$currentUser.role === process.env.VUE_APP_MAIN_ROLE || this.$currentUser.global === true) {
      this.tableHeaders = this.$helper.addToArray(this.tableHeaders, {
        text: this.$t('affiliates.table.team'),
        value: 'team'
      }, 1)
      this.formScheme = this.$helper.addToObject(this.formScheme, 'team_id', {
        type: 'autocomplete',
        label: this.$t('affiliates.tableForm.team'),
        items: await this.$store.dispatch('affiliatesTeams/all'),
        createRules: [], // v => !!v || this.$t('domains.tableForm.validation.fieldIsRequired')
        updateRules: [],
        md: 6
      }, 'status')
    }
  },
  methods: {
    async sync() {
      try {
        this.syncLoading = true
        await this.$store.dispatch('affiliates/sync')
      } catch (e) {
        await this.$toast.error(e.message)
      } finally {
        this.syncLoading = false
      }
    },
    currentItems(items) {
      items.forEach((i) => {
        if (i.affiliate_hash !== false) {
          this.$set(i, 'disabled', true)
        }
      })
    }
  }
}
</script>
